import type { FC } from 'react'
import { useMemo } from 'react'
import { useParams } from 'react-router'

import { find, flow, getPropertyValue, isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import {
  useAccommodationDetailsQuery,
  useDestinationsQuery,
  useHistoryAccommodationDetailsQuery,
  useIdToken,
  useRoomDetailsQuery,
} from '../../../../hooks'
import type { HistoryDetailsAccommodationPathParams } from '../../../../models'
import { Path } from '../../../../models'
import {
  createAccommodationGuestsString,
  createRoomNameString,
  dateFormatter,
  isDestinationOf,
  to,
} from '../../../../utils'
import { BookingSummaryAccommodation, ButtonBuyAccommodation, LayoutNavigationBackError404 } from '../../../common'
import { LayoutHistoryDetails } from '../common'

import { HistoryDetailsAccommodationLoading } from './HistoryDetailsAccommodationLoading'

const BACK_NAVIGATION_PATH = to(Path.History)

export const HistoryDetailsAccommodation: FC = () => {
  const idToken = useIdToken()
  const params = useParams<HistoryDetailsAccommodationPathParams>()

  const currentAccommodation = useAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId: Number(params.accommodationId) },
    { enabled: isNotNull(idToken) && isNotUndefined(params.accommodationId) }
  )

  const historyAccommodation = useHistoryAccommodationDetailsQuery(
    { idToken: String(idToken), accommodationId: Number(params.accommodationId) },
    { enabled: isNotNull(idToken) && isNotUndefined(params.accommodationId) && currentAccommodation.isError }
  )

  const accommodation = useMemo(
    () => (currentAccommodation.isError ? historyAccommodation : currentAccommodation),
    [currentAccommodation, historyAccommodation]
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(accommodation.data))),
    enabled: isNotUndefined(accommodation.data),
  })

  const roomDetails = useRoomDetailsQuery(
    { destinationId: String(destination.data?.destinationId), roomId: String(accommodation.data?.room_type_id) },
    { enabled: isNotUndefined(destination.data?.destinationId) && isNotUndefined(accommodation.data?.room_type_id) }
  )

  if (isUndefined(params.accommodationId) || accommodation.isError) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (accommodation.isSuccess) {
    return (
      <LayoutHistoryDetails
        backNavigationPath={BACK_NAVIGATION_PATH}
        cta={<ButtonBuyAccommodation resortId={accommodation.data.resort_id} variant='outlined' color='black' />}
        headerPrimary={roomDetails.isSuccess ? createRoomNameString(roomDetails.data) : accommodation.data.room_type}
        headerSecondary={dateFormatter.formatDateRange({
          from: accommodation.data.start_date,
          to: accommodation.data.end_date,
        })}
        showFallbackWarning={
          destination.isError || (destination.isSuccess && isUndefined(destination.data)) || roomDetails.isError
        }
      >
        <BookingSummaryAccommodation
          accommodationId={accommodation.data.accommodation_id}
          accommodationStatus={accommodation.data.status}
          bookingDate={accommodation.data.booking_date}
          checkInDate={accommodation.data.start_date}
          checkInHour={roomDetails.data?.checkInHour}
          checkOutDate={accommodation.data.end_date}
          checkOutHour={roomDetails.data?.checkOutHour}
          destinationName={destination.data?.title}
          guests={createAccommodationGuestsString(accommodation.data)}
          paymentStatus={accommodation.data.payment_status}
          price={accommodation.data.total_amount}
        />
        {/* // TODO 128: Restore price summaries on booking details when a bug in HotelLinx is fixed */}
        {/* //  https://hcrdevelopment.atlassian.net/browse/SII-128 */}
        {/* // <BookingSummaryPrice items={accommodation.data.prices} total={accommodation.data.total_amount} /> */}
      </LayoutHistoryDetails>
    )
  }

  return <HistoryDetailsAccommodationLoading backNavigationPath={BACK_NAVIGATION_PATH} />
}
