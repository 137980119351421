import { z } from 'zod'

import { BooleanDtoSchema, StringDtoSchema } from '@hcr/api/shared'

import { CountryDtoSchema } from './CountryDto'
import { MarketingPermissionsDtoSchema } from './MarketingPermissionsDto'
import { UserDetailsOwnershipDtoSchema } from './OwnershipDto'

export const UserDetailsDtoSchema = z.strictObject({
  first_name: StringDtoSchema,
  last_name: StringDtoSchema,
  email: StringDtoSchema.email(),
  country_code: StringDtoSchema,
  phone: StringDtoSchema,
  street_address: StringDtoSchema,
  street_address2: StringDtoSchema.nullable(),
  postal_code: StringDtoSchema,
  city: StringDtoSchema,
  country: CountryDtoSchema,
  has_benefits: BooleanDtoSchema,
  has_active_contracts: BooleanDtoSchema,
  customer_id: StringDtoSchema.nullable(),
  ownership: UserDetailsOwnershipDtoSchema,
  marketing_permissions: MarketingPermissionsDtoSchema,
})

export type UserDetailsDto = z.infer<typeof UserDetailsDtoSchema>
