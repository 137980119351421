import type { MocksSettings } from '../models'
import { Mocks } from '../models'

interface MocksConfig {
  default: MocksSettings
  storageKeyName: string
}

export const MOCKS_CONFIG: MocksConfig = {
  default: {
    [Mocks.HasAccommodations]: true,
    [Mocks.HasActiveContracts]: true,
    [Mocks.HasActivities]: true,
    [Mocks.HasBenefits]: true,
    [Mocks.HasCoupons]: true,
    [Mocks.Holding]: '',
    [Mocks.HasHotelRoom]: false,
    [Mocks.HasSingleTickets]: true,
    [Mocks.IsB2BBasicOwner]: false,
    [Mocks.IsB2BGoldOwner]: false,
    [Mocks.IsB2BVillasOwner]: false,
    [Mocks.IsGoldOwner]: true,
    [Mocks.IsVillasOwner]: false,
  },
  storageKeyName: 'MOCKS_SETTINGS',
}
