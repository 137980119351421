import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@mui/material'
import { t } from 'i18next'

import { unit, COLORS, ICONS } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { dateFormatter } from '../../../../../utils'
import type { BookingListPreviewProps } from '../../../../common/booking-list/types'

interface BookingListDescriptionProps extends Pick<BookingListPreviewProps, 'dateRange' | 'ticketsCount'> {
  isActive?: boolean
}

export const BookingListDescription = ({ dateRange, ticketsCount, isActive }: BookingListDescriptionProps) => {
  const color = isActive ? COLORS.white : 'initial'

  return (
    <Box display='inline'>
      <Box display='inline' marginRight={unit(3.5)}>
        {isNotUndefined(dateRange) ? (
          <Typography variant='meta' fontWeight='initial' color={color}>
            {dateFormatter.formatDateRange(dateRange)}
          </Typography>
        ) : (
          <Typography variant='meta' fontWeight='initial' color={color}>
            {t('booking.no-accommodation-booked')}
          </Typography>
        )}
      </Box>

      {isNotUndefined(ticketsCount) && ticketsCount > 0 && (
        <Typography variant='meta' fontWeight='initial' color={color} sx={{ whiteSpace: 'nowrap' }}>
          <FontAwesomeIcon
            color={isActive ? COLORS.white : COLORS.grey[600]}
            icon={ICONS.farLayerGroup}
            style={{ marginRight: unit(1) }}
          />
          {ticketsCount > 1
            ? t('utils.tickets-count-plural', { count: ticketsCount })
            : t('utils.tickets-count', { count: ticketsCount })}
        </Typography>
      )}
    </Box>
  )
}
