import { z } from 'zod'

import { BooleanDtoSchema, DoubleDtoSchema, Int32DtoSchema, StringDtoSchema } from '@hcr/api/shared'

import { ActivityStatusDtoSchema } from './ActivityStatusDto'
import { PaymentStatusDtoSchema } from './PaymentStatusDto'

export const ServiceReservationDtoSchema = z.strictObject({
  name: StringDtoSchema,
  sub_total_amount: DoubleDtoSchema,
  quantity: Int32DtoSchema,
})

export const ActivityDtoSchema = z.strictObject({
  activity_id: StringDtoSchema,
  booking_date: StringDtoSchema.datetime(),
  cancellation_term: StringDtoSchema.nullable(),
  description: StringDtoSchema.nullable(),
  details: StringDtoSchema,
  end_date: StringDtoSchema.datetime(),
  is_activity_share_reservation: BooleanDtoSchema,
  is_manual_reservation: BooleanDtoSchema,
  name: StringDtoSchema,
  payment_status: PaymentStatusDtoSchema,
  resort_id: Int32DtoSchema,
  start_date: StringDtoSchema.datetime(),
  status: ActivityStatusDtoSchema,
  total_amount: DoubleDtoSchema,
  sub_total_amount: DoubleDtoSchema.nullable(),
  service_reservations: z.array(ServiceReservationDtoSchema),
})

export type ActivityDto = z.infer<typeof ActivityDtoSchema>
export type ServiceReservationDto = z.infer<typeof ServiceReservationDtoSchema>

export const isActivityDto = (x: unknown): x is ActivityDto => ActivityDtoSchema.safeParse(x).success
