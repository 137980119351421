import type { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Analytics as AnalyticsClient } from 'analytics'
import type { FC } from 'react'
import { Outlet, ScrollRestoration } from 'react-router'
import { AnalyticsProvider } from 'use-analytics'

import { globalCss, light } from '@hcr/ui'

import { ANALYTICS_CONFIG, HTTP_CONFIG } from '../../configs'
import { ConsentSettingsProvider, HolidayProvider, LastPathProvider, UserDetailsProvider } from '../../contexts'
import { Analytics } from '../analytics'

interface AppProps {
  msalInstance: PublicClientApplication
}

const analyticsInstance = AnalyticsClient(ANALYTICS_CONFIG.analytics)
const queryClient = new QueryClient(HTTP_CONFIG.query)
const customGlobalStyles = <GlobalStyles styles={globalCss} />

export const App: FC<AppProps> = ({ msalInstance }) => {
  return (
    <AnalyticsProvider instance={analyticsInstance}>
      <MsalProvider instance={msalInstance}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={light}>
              {customGlobalStyles}
              <LastPathProvider>
                <HolidayProvider>
                  <UserDetailsProvider>
                    <ConsentSettingsProvider>
                      <Analytics>
                        <Outlet />
                      </Analytics>
                    </ConsentSettingsProvider>
                    <ScrollRestoration />
                    <ReactQueryDevtools initialIsOpen={false} />
                  </UserDetailsProvider>
                </HolidayProvider>
              </LastPathProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </MsalProvider>
    </AnalyticsProvider>
  )
}
