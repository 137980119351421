import { z } from 'zod'

import { BooleanDtoSchema } from '@hcr/api/shared'

export const UserDetailsOwnershipDtoSchema = z.strictObject({
  is_active_gold: BooleanDtoSchema,
  is_b2b_basic: BooleanDtoSchema,
  is_b2b_gold: BooleanDtoSchema,
  is_b2b_villas: BooleanDtoSchema,
  is_villas: BooleanDtoSchema,
})

export type OwnershipDto = z.infer<typeof UserDetailsOwnershipDtoSchema>
