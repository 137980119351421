import { Skeleton } from '@mui/material'
import type { FC, ReactNode } from 'react'

import { unit } from '../../../utils'
import { CardEmbedment, CardPrimaryLoading, CardVariant } from '../../surfaces'

import { Carousel } from './Carousel'

interface CarouselLoadingProps {
  children?: [string, ReactNode][]
  slidesMargin: string
}

export const CarouselLoading: FC<CarouselLoadingProps> = ({ children, slidesMargin }) => {
  return (
    <Carousel
      slidesMargin={slidesMargin}
      title={<Skeleton width={unit(45)} height={unit(6.5)} variant='rectangular' />}
    >
      {children ?? [
        [
          'card-primary-loading-1',
          <CardPrimaryLoading
            embedment={CardEmbedment.Carousel}
            variant={CardVariant.Regular}
            key='card-primary-loading-1'
          />,
        ],
        [
          'card-primary-loading-2',
          <CardPrimaryLoading
            embedment={CardEmbedment.Carousel}
            variant={CardVariant.Regular}
            key='card-primary-loading-2'
          />,
        ],
        [
          'card-primary-loading-3',
          <CardPrimaryLoading
            embedment={CardEmbedment.Carousel}
            variant={CardVariant.Regular}
            key='card-primary-loading-3'
          />,
        ],
        [
          'card-primary-loading-4',
          <CardPrimaryLoading
            embedment={CardEmbedment.Carousel}
            variant={CardVariant.Regular}
            key='card-primary-loading-4'
          />,
        ],
      ]}
    </Carousel>
  )
}
