import { Box, Button, CardMedia, Divider, Stack, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { COLORS, hideEmptyCss, LinkUnstyled, optimizelyCss, unit } from '@hcr/ui'
import { hasMoreThanOneItem, isEmpty, isNotEmpty, isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import { useBuildingDetailsQuery } from '../../../../hooks'
import type { OwnerHoldingDetailsPathParams } from '../../../../models'
import { Path } from '../../../../models'
import {
  createBuildingDetailsContent,
  createHoldingNameString,
  isHoldingVilla,
  selectNextHoldingWeeks,
  to,
} from '../../../../utils'
import { LayoutNavigationBack, LayoutNavigationBackError404, WarningMissingData } from '../../../common'
import { WeekPreview } from '../common'
import { useHolding } from '../hooks'

import { OwnerHoldingDetailsLoading } from './OwnerHoldingDetailsLoading'

const BACK_NAVIGATION_PATH = to(Path.Owner)

const buildingDetailsContent = createBuildingDetailsContent()

export const OwnerHoldingDetails: FC = () => {
  const { t } = useTranslation()
  const params = useParams<OwnerHoldingDetailsPathParams>()

  const holding = useHolding({ holdingId: params.holdingId })

  const buildingDetails = useBuildingDetailsQuery(
    { buildingId: String(holding.data?.building_id), isContracted: true },
    { enabled: isNotUndefined(holding.data?.building_id) }
  )

  if (
    isUndefined(params.holdingId) ||
    holding.isError ||
    (holding.isSuccess && isUndefined(holding.data)) ||
    buildingDetails.isError
  ) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (holding.isSuccess && isNotUndefined(holding.data) && buildingDetails.isSuccess) {
    const weeks = selectNextHoldingWeeks(holding.data)

    return (
      <LayoutNavigationBack to={BACK_NAVIGATION_PATH} arrowVariant='outlined'>
        <Box bgcolor={COLORS.white} minHeight='100vh'>
          <CardMedia image={buildingDetails.data.imageUrl} sx={{ height: unit(71) }} />
          <Stack gap={unit(4)} paddingY={unit(9)} paddingX={unit(8)}>
            <Stack gap={unit(1.5)}>
              <Typography variant='headlineL'>{buildingDetails.data.title}</Typography>
              {isNotNull(buildingDetails.data.destinationName) && (
                <Typography variant='titleL'>{buildingDetails.data.destinationName}</Typography>
              )}
              <Typography variant='bodyM'>{createHoldingNameString(holding.data)}</Typography>
            </Stack>
            <Divider sx={{ borderStyle: 'dashed' }} />
            {isEmpty(holding.data.weeks) && isEmpty(weeks) && <WarningMissingData />}
            {(isNotEmpty(holding.data.weeks) || isNotEmpty(weeks)) && (
              <WeekPreview
                holdingId={holding.data.holding_id}
                isVilla={isHoldingVilla(holding.data)}
                week={weeks[0] || holding.data.weeks[0]}
              />
            )}
            {hasMoreThanOneItem(holding.data.weeks) && (
              <Button
                component={LinkUnstyled}
                to={to([Path.OwnerHoldingWeeks, { holdingId: holding.data.holding_id }])}
                variant='outlined'
                color='black'
              >
                {t('owner-holding.more-weeks')}
              </Button>
            )}
            <Typography
              variant='bodyM'
              css={optimizelyCss}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(buildingDetails.data.description) }}
            />
          </Stack>
          <Stack gap={unit(3)} paddingX={unit(3)} paddingBottom={unit(3)} css={hideEmptyCss}>
            {buildingDetails.data.content.map(buildingDetailsContent)}
          </Stack>
        </Box>
      </LayoutNavigationBack>
    )
  }

  return <OwnerHoldingDetailsLoading backNavigationPath={BACK_NAVIGATION_PATH} />
}
