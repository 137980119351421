import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, IconButton, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { ICONS, LinkUnstyled, unit } from '@hcr/ui'

import { SvgBubbleSuccess } from '../../../assets'
import { AUTH_CONFIG, LINK_CONFIG } from '../../../configs'
import { Path } from '../../../models'
import { to } from '../../../utils'
import { ButtonSignIn, LayoutBase } from '../../common'

export const OnboardingCompletionSuccess: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <LayoutBase>
      <Container>
        <StyledIconButton onClick={() => navigate(to(Path.Home))} aria-label={t('onboarding.close')}>
          <FontAwesomeIcon icon={ICONS.farXmark} />
        </StyledIconButton>
        <Content>
          <Typography variant='headlineL'>{t('onboarding.account-created-successfully')}</Typography>
          <Typography variant='bodyM'>{t('onboarding.welcome-to-holiday-club')}</Typography>
        </Content>
        <SvgBubbleSuccess />
        <ButtonSignIn
          request={{ scopes: [], redirectStartPage: AUTH_CONFIG.msal.auth.redirectUri }}
          variant='contained'
          color='primary'
        >
          {t('onboarding.sign-in-to-loma')}
        </ButtonSignIn>
      </Container>
    </LayoutBase>
  )
}

const Container = styled(Stack)`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: ${unit(7)};
  padding: ${unit(7)};
`

const StyledIconButton = styled(IconButton)`
  align-self: flex-end;
  position: absolute;
  top: ${unit(6)};
  right: ${unit(6)};
  cursor: pointer;
  font-size: ${unit(6)};
`

const Content = styled(Stack)`
  align-items: center;
  gap: ${unit(2.5)};
  text-align: center;
`
