import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { COLORS, ICONS, LinkUnstyled, unit } from '@hcr/ui'
import { isNull, isUndefined } from '@hcr/utils'

import { GOMEDDO_CONFIG } from '../../../configs/gomeddo.config'
import { useLastPath } from '../../../contexts'
import { useGlobalId, useLocale } from '../../../hooks'
import type { GomeddoBookingPathParams } from '../../../models'
import { LayoutBase, LayoutNavigationBackError404, LayoutNavigationBackError500 } from '../../common'

export const GomeddoBooking: FC = () => {
  const { t } = useTranslation()
  const params = useParams<GomeddoBookingPathParams>()
  const globalId = useGlobalId()
  const locale = useLocale()
  const lastPath = useLastPath()

  if (isUndefined(params.gomeddoId)) {
    return <LayoutNavigationBackError404 to={lastPath.recent} />
  }

  if (isNull(globalId)) {
    return <LayoutNavigationBackError500 to={lastPath.recent} />
  }

  return (
    <LayoutBase>
      <Stack height='100%'>
        <Box bgcolor={COLORS.burgundi[500]}>
          <Box marginY={unit(4)} marginX={unit(3)} width='fit-content'>
            <LinkUnstyled to={lastPath.recent}>
              <Stack direction='row' alignItems='center' color={COLORS.white} gap={unit(2)}>
                <Stack justifyContent='center' width={unit(14)} height={unit(14)} fontSize={unit(6)}>
                  <FontAwesomeIcon icon={ICONS.farArrowLeft} />
                </Stack>
                <Box paddingRight={unit(4)}>
                  <Typography variant='bodyL'>{t('gomeddo-booking.go-back')}</Typography>
                </Box>
              </Stack>
            </LinkUnstyled>
          </Box>
        </Box>
        <Box flex='auto'>
          <gomeddo-widget
            account={globalId}
            auto={GOMEDDO_CONFIG.widget.auto}
            business={GOMEDDO_CONFIG.widget.business}
            page={GOMEDDO_CONFIG.widget.page}
            resource={params.gomeddoId}
            lang={locale.id}
          ></gomeddo-widget>
        </Box>
      </Stack>
    </LayoutBase>
  )
}
