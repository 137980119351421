import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, Stack, styled, Typography } from '@mui/material'
import type { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import type { StepperProps } from '@hcr/ui'
import { ICONS, Stepper, unit } from '@hcr/ui'

import { Path } from '../../../../models'
import { to } from '../../../../utils'
import { LayoutBase } from '../../../common'

interface LayoutOnboardingProps {
  description: string
  footer: ReactNode
  form: ReactNode
  headline: string
  stepper: StepperProps
}

export const LayoutOnboarding: FC<LayoutOnboardingProps> = ({ description, footer, form, headline, stepper }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { highlight, of } = stepper

  return (
    <LayoutBase>
      <Container>
        <IconButton
          aria-label={t('onboarding.close')}
          onClick={() => navigate(to(Path.Home))}
          sx={{ fontSize: unit(6), alignSelf: 'end' }}
        >
          <FontAwesomeIcon icon={ICONS.farXmark} />
        </IconButton>
        <Stepper highlight={highlight} of={of} />
        <Stack gap={unit(4)} textAlign='center'>
          <Typography variant='headlineL'>{headline}</Typography>
          <Typography variant='bodyM'>{description}</Typography>
        </Stack>
        <Box width='100%'>{form}</Box>
        <Stack width='100%' alignItems='center'>
          {footer}
        </Stack>
      </Container>
    </LayoutBase>
  )
}

const Container = styled(Stack)`
  align-items: center;
  gap: ${unit(6)};
  padding: ${unit(6)};
`
