import { Skeleton } from '@mui/material'
import type { FC } from 'react'
import type { To } from 'react-router'

import { unit } from '@hcr/ui'

import { BenefitsGroupLoading } from '../benefits-group'

import { LayoutOwnerBenefits } from './LayoutOwnerBenefits'

interface LayoutOwnerBenefitsLoadingProps {
  backNavigationPath: To
}

export const LayoutOwnerBenefitsLoading: FC<LayoutOwnerBenefitsLoadingProps> = ({ backNavigationPath }) => {
  return (
    <LayoutOwnerBenefits
      backNavigationPath={backNavigationPath}
      description={
        <>
          <Skeleton width='95%' height={unit(6)} />
          <Skeleton width='85%' height={unit(6)} />
          <Skeleton width='90%' height={unit(6)} />
        </>
      }
      title={<Skeleton width='80%' height={unit(9.5)} variant='rectangular' />}
    >
      <BenefitsGroupLoading />
    </LayoutOwnerBenefits>
  )
}
