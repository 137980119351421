import type { FC } from 'react'
import { useParams, useSearchParams } from 'react-router'

import { isSingleTicketStatusDto } from '@hcr/api/consumer'
import {
  filter,
  flow,
  hasMoreThanOneItem,
  hasOneItem,
  hasPropertyValue,
  isEmpty,
  isNotEmpty,
  isNotNull,
  isNotUndefined,
  isUndefined,
} from '@hcr/utils'

import { useIdToken, useSingleTicketsQuery } from '../../../../hooks'
import type { TicketDetailsSinglePathParams } from '../../../../models'
import { Path, TicketDetailsSingleSearchParamsKeys } from '../../../../models'
import { to } from '../../../../utils'
import { LayoutNavigationBackError404, LayoutNavigationBackLoading } from '../../../common'

import { TicketDetailsSingleActiveBundle } from './TicketDetailsSingleActiveBundle'
import { TicketDetailsSingleActiveOne } from './TicketDetailsSingleActiveOne'
import { TicketDetailsSingleInactiveBundle } from './TicketDetailsSingleInactiveBundle'
import { TicketDetailsSingleInactiveOne } from './TicketDetailsSingleInactiveOne'

const BACK_NAVIGATION_PATH = to(Path.Booking)

export const TicketDetailsSingle: FC = () => {
  const idToken = useIdToken()
  const params = useParams<TicketDetailsSinglePathParams>()
  const [searchParams] = useSearchParams()

  const categoryIdPathParam = params.categoryId

  const resortsIdsSearchParam = searchParams.get(TicketDetailsSingleSearchParamsKeys.ResortsIds)
  const resortsIds = isNotNull(resortsIdsSearchParam)
    ? resortsIdsSearchParam.split(',').filter(isNotEmpty).map(Number).filter(Number.isInteger)
    : []

  const statusSearchParam = searchParams.get(TicketDetailsSingleSearchParamsKeys.Status)
  const status = isSingleTicketStatusDto(statusSearchParam) ? statusSearchParam : undefined

  const timeslotSearchParam = searchParams.get(TicketDetailsSingleSearchParamsKeys.Timeslot)
  const timeslot = isNotNull(timeslotSearchParam) ? timeslotSearchParam : null

  const singleTickets = useSingleTicketsQuery(
    { idToken: String(idToken), resortsIds },
    {
      select: flow(
        filter(hasPropertyValue('category_id', categoryIdPathParam)),
        filter(hasPropertyValue('status', status)),
        filter(hasPropertyValue('timeslot', timeslot))
      ),
      enabled:
        isNotNull(idToken) && isNotUndefined(categoryIdPathParam) && isNotEmpty(resortsIds) && isNotUndefined(status),
    }
  )

  if (isUndefined(categoryIdPathParam) || isEmpty(resortsIds) || isUndefined(status) || singleTickets.isError) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (singleTickets.isSuccess) {
    if (hasMoreThanOneItem(singleTickets.data)) {
      switch (status) {
        case 'Active':
          return (
            <TicketDetailsSingleActiveBundle
              resortsIds={resortsIds}
              ticketsIds={singleTickets.data.map(ticket => ticket.ticket_id)}
            />
          )
        case 'Inactive':
          return (
            <TicketDetailsSingleInactiveBundle
              resortsIds={resortsIds}
              ticketsIds={singleTickets.data.map(ticket => ticket.ticket_id)}
            />
          )
        case 'Used':
          return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
      }
    }

    if (hasOneItem(singleTickets.data)) {
      switch (status) {
        case 'Active':
          return <TicketDetailsSingleActiveOne resortsIds={resortsIds} ticketId={singleTickets.data[0].ticket_id} />
        case 'Inactive':
          return <TicketDetailsSingleInactiveOne resortsIds={resortsIds} ticketId={singleTickets.data[0].ticket_id} />
        case 'Used':
          return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
      }
    }

    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
