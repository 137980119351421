import { zodResolver } from '@hookform/resolvers/zod'
import { Button, FormControl, FormControlLabel, FormHelperText, Stack, TextField, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { isValidIBAN } from 'ibantools'
import type { FC } from 'react'
import { useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router'
import { z } from 'zod'

import { createHoldingsQueryKey } from '@hcr/api/consumer'
import { Alert, ButtonLoading, Checkbox, LinkTrans, LinkUnstyled, unit } from '@hcr/ui'
import { isNotUndefined, isUndefined, logger } from '@hcr/utils'

import { LINK_CONFIG, SENTRY_CONFIG } from '../../../../configs'
import { useHoldingRentalMutation, useIdToken } from '../../../../hooks'
import type { OwnerHoldingRentalPathParams } from '../../../../models'
import { createHoldingNameString } from '../../../../utils'
import { LayoutNavigationBackError404 } from '../../../common'
import { ContractPreview, LayoutOwnerHoldingAction, LayoutOwnerHoldingActionLoading } from '../common'
import { useBackNavigationPath, useHolding, useWeek } from '../hooks'

export const OwnerHoldingRental: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const params = useParams<OwnerHoldingRentalPathParams>()
  const queryClient = useQueryClient()

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const openConfirmation = () => setIsConfirmationOpen(true)
  const cancelConfirmation = () => setIsConfirmationOpen(false)

  const holding = useHolding({ holdingId: params.holdingId })
  const holdingRental = useHoldingRentalMutation({ idToken: String(idToken) })
  const week = useWeek({ holding: holding.data, weekStart: params.weekStart })

  const backNavigationPath = useBackNavigationPath({ holdingId: params.holdingId })

  const schema = z.strictObject({
    bank_account_number: z.string().refine(isValidIBAN, { message: t('validation.type-iban') }),
    terms_of_rental_consent: z.boolean().refine(value => value, { message: t('validation.required') }),
  })

  type Schema = z.infer<typeof schema>

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      bank_account_number: '',
      terms_of_rental_consent: false,
    },
  })

  const onSubmit: SubmitHandler<Schema> = async data => {
    try {
      if (isNotUndefined(holding.data) && isNotUndefined(week)) {
        await holdingRental.mutateAsync({
          bank_account_number: data.bank_account_number,
          contract_id: holding.data.contract_id,
          end_date: week.week_end,
          room_number: holding.data.room_number,
          start_date: week.week_start,
          week_number: week.week_number,
        })
        await queryClient.invalidateQueries({ queryKey: createHoldingsQueryKey() })
      }
    } catch {
      logger.error('Failed to rent out the week:', week)
      cancelConfirmation()
    }
  }

  if (
    isUndefined(params.holdingId) ||
    isUndefined(params.weekStart) ||
    holding.isError ||
    (holding.isSuccess && isUndefined(holding.data)) ||
    (holding.isSuccess && isUndefined(week))
  ) {
    return <LayoutNavigationBackError404 to={backNavigationPath} />
  }

  if (holding.isSuccess && isNotUndefined(holding.data) && isNotUndefined(week)) {
    if (week.is_rental_request_received) {
      return (
        <LayoutOwnerHoldingAction
          backNavigationPath={backNavigationPath}
          header={<Typography variant='headlineL'>{t('owner-holding.rental-requested')}</Typography>}
        >
          <ContractPreview
            buildingId={holding.data.building_id}
            holdingName={createHoldingNameString(holding.data)}
            week={week}
          />
          <Typography variant='bodyM'>{t('owner-holding.your-rental-request-has-been-sent')}</Typography>
          <Button component={LinkUnstyled} to={backNavigationPath} variant='outlined' color='black'>
            {t('owner-holding.close')}
          </Button>
        </LayoutOwnerHoldingAction>
      )
    }

    if (week.is_locked) {
      return <Navigate to={backNavigationPath} replace />
    }

    return (
      <LayoutOwnerHoldingAction
        backNavigationPath={backNavigationPath}
        drawer={
          <>
            <Typography variant='headlineS' marginBottom={unit(3)}>
              {t('owner-holding.rental-request')}
            </Typography>
            <Typography variant='bodyM'>
              {t(
                'owner-holding.if-you-are-in-the-rci-points-system-please-make-sure-you-have-booked-a-week-for-your-own-use-drawer'
              )}
            </Typography>
          </>
        }
        header={<Typography variant='headlineL'>{t('owner-holding.make-a-rental-request')}</Typography>}
      >
        <ContractPreview
          buildingId={holding.data.building_id}
          holdingName={createHoldingNameString(holding.data)}
          week={week}
        />
        <Alert
          severity='info'
          title={t('owner-holding.info')}
          description={t(
            'owner-holding.if-you-are-in-the-rci-points-system-please-make-sure-you-have-booked-a-week-for-your-own-use'
          )}
        />
        <Typography variant='bodyL'>
          {t('owner-holding.please-provide-your-bank-account-number-for-the-rental-income-payment')}
        </Typography>
        <form noValidate>
          <Stack gap={unit(3)}>
            <Controller
              control={form.control}
              name='bank_account_number'
              render={({ field, fieldState }) => (
                <TextField
                  className={SENTRY_CONFIG.privacy.className}
                  value={field.value}
                  onChange={field.onChange}
                  label={t('owner-holding.bank-account-number')}
                  error={isNotUndefined(fieldState.error)}
                  helperText={fieldState.error?.message}
                  placeholder='FI0000000000000000'
                  required
                />
              )}
            />
            <Typography variant='meta' paddingLeft={unit(3)}>
              {t('owner-holding.required-information')}
            </Typography>
            <Controller
              control={form.control}
              name='terms_of_rental_consent'
              render={({ field, fieldState }) => (
                <FormControl error={isNotUndefined(fieldState.error)} component='fieldset'>
                  <FormControlLabel
                    label={
                      <Typography variant='bodyM'>
                        <Trans i18nKey='owner-holding.consent-terms-of-rental'>
                          I agree to the agency's
                          <LinkTrans to={LINK_CONFIG.termsOfRental} target='_blank' rel='external'>
                            terms of rental
                          </LinkTrans>
                        </Trans>
                      </Typography>
                    }
                    control={<Checkbox checked={field.value} onChange={field.onChange} required />}
                  />
                  {isNotUndefined(fieldState.error) && isNotUndefined(fieldState.error.message) && (
                    <FormHelperText>{fieldState.error.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Stack>
        </form>
        {holdingRental.isError && (
          <Alert
            severity='error'
            title={t('owner-holding.rental-failed')}
            description={t('owner-holding.an-error-occurred-while-renting-out')}
          />
        )}
        {isConfirmationOpen ? (
          <Stack gap={unit(1.5)}>
            <ButtonLoading
              onClick={form.handleSubmit(onSubmit)}
              isLoading={form.formState.isSubmitting}
              variant='contained'
              color='success'
            >
              {t('owner-holding.confirm')}
            </ButtonLoading>
            <Button
              onClick={cancelConfirmation}
              disabled={form.formState.isSubmitting}
              variant='outlined'
              color='black'
            >
              {t('owner-holding.cancel')}
            </Button>
          </Stack>
        ) : (
          <Button onClick={openConfirmation} variant='contained' color='primary'>
            {t('owner-holding.rent-out')}
          </Button>
        )}
      </LayoutOwnerHoldingAction>
    )
  }

  return <LayoutOwnerHoldingActionLoading backNavigationPath={backNavigationPath} />
}
