import type { FC, ReactNode } from 'react'
import { Outlet } from 'react-router'

interface RestrictedProps {
  fallback: ReactNode
  isAllowed: boolean
}

export const Restricted: FC<RestrictedProps> = ({ fallback, isAllowed }) => {
  if (isAllowed) {
    return <Outlet />
  }

  return fallback
}
