import { Box, Button, Stack, Typography } from '@mui/material'
import hash from 'object-hash'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { OwnershipDto } from '@hcr/api/consumer'
import { CardEmbedment, CardPrimary, CardPromotion, CardVariant, COLORS, LinkUnstyled, unit } from '@hcr/ui'

import { LINK_CONFIG } from '../../../../configs'
import { useUserDetails } from '../../../../contexts'
import { useLocale, useOwnersQuery } from '../../../../hooks'
import { Path } from '../../../../models'
import { createOwnersContent, to } from '../../../../utils'
import { Background, LayoutNavigation, LayoutNavigationError500 } from '../../../common'

import { Holdings, OwnerCard } from './common'
import { OwnerLoading } from './OwnerLoading'
import { OwnerUncontracted } from './OwnerUncontracted'

export const Owner: FC = () => {
  const { t } = useTranslation()
  const locale = useLocale()

  const userDetails = useUserDetails()
  const { is_villas, is_active_gold, is_b2b_gold, is_b2b_villas, is_b2b_basic } =
    (userDetails.data?.ownership as OwnershipDto) || {}

  const isVillasOwnerNoBenefits = Boolean(is_villas) && Boolean(!userDetails.data?.has_benefits)
  const hasBenefits = isVillasOwnerNoBenefits || userDetails.data?.has_benefits
  const isB2BOwner = is_b2b_basic || is_b2b_gold || is_b2b_villas

  const owners = useOwnersQuery(
    {
      hasBenefits: isVillasOwnerNoBenefits || Boolean(userDetails.data?.has_benefits),
      isContracted: true,
      isGoldOwner: !isVillasOwnerNoBenefits && (Boolean(is_active_gold) || Boolean(is_b2b_gold)),
      isVillasOwner: !isVillasOwnerNoBenefits && (Boolean(is_villas) || Boolean(is_b2b_villas)),
      isVillasOwnerNoBenefits,
    },
    { enabled: userDetails.isSuccess && userDetails.data?.has_active_contracts }
  )

  if (userDetails.isError || owners.isError) {
    return <LayoutNavigationError500 />
  }

  if (userDetails.isSuccess && !userDetails.data?.has_active_contracts) {
    return <OwnerUncontracted />
  }

  if (userDetails.isSuccess && owners.isSuccess) {
    const ownersContent = createOwnersContent({
      slidesMargin: unit(3),
      variant: CardVariant.Regular,
      additionalCard: hasBenefits
        ? [
            hash([t('owner.take-advantage-of-your-benefits')]),
            <CardPromotion
              key={hash([t('owner.take-advantage-of-your-benefits')])}
              description=''
              embedment={CardEmbedment.Carousel}
              link={{
                isExternal: false,
                label: t('owner.see-all-benefits'),
                to: to(Path.OwnerBenefits),
              }}
              title={t('owner.take-advantage-of-your-benefits')}
              variant={CardVariant.Owner}
            />,
          ]
        : undefined,
    })

    return (
      <LayoutNavigation>
        <Box paddingX={unit(3)} bgcolor={COLORS.white}>
          <Background url={owners.data.imageUrl}>
            {userDetails.data?.has_benefits && <OwnerCard />}
            <Stack
              marginTop={userDetails.data?.has_benefits ? unit(10.75) : unit(64)}
              padding={unit(5)}
              bgcolor={COLORS.red.dark}
              color={COLORS.white}
            >
              <Typography variant='displayM' marginBottom={unit(4)}>
                {owners.data.title}
              </Typography>
              <Typography variant='bodyM' marginBottom={unit(7)}>
                {owners.data.description}
              </Typography>
            </Stack>
          </Background>
          <Stack gap={unit(3)} paddingY={unit(3)}>
            {isB2BOwner ? (
              <CardPrimary
                badge={null}
                description={t(
                  'owner.your-own-weeks-through-the-company-will-not-appear-on-your-own-holiday-page-if-you-want-to-rent-out-your-week-do-it-easily-using-the-form'
                )}
                embedment={CardEmbedment.Standalone}
                imageUrl={null}
                link={{
                  isExternal: true,
                  label: t('owner.for-rental'),
                  to: LINK_CONFIG.rentYourOwnHolidayWeek[locale.id],
                }}
                title={t('owner.your-holdings')}
                variant={CardVariant.Regular}
              />
            ) : (
              <Holdings />
            )}

            {ownersContent(owners.data.content[0])}

            {hasBenefits && (
              <Button component={LinkUnstyled} to={to(Path.OwnerBenefits)} variant='contained' color='primary'>
                {t('owner.see-all-benefits')}
              </Button>
            )}

            {owners.data.content.slice(1).map(ownersContent)}
          </Stack>
        </Box>
      </LayoutNavigation>
    )
  }

  return <OwnerLoading />
}
