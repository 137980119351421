import type { HoldingDto } from '@hcr/api/consumer'
import { filter, flow, getPropertyValue, isNotEmpty, map } from '@hcr/utils'

import { compareDates } from '../../../dates'

const sortWeeksByDate = (weeks: HoldingDto['weeks']): HoldingDto['weeks'] => {
  const today = new Date()

  const futureWeeks = weeks.filter(w => new Date(w.week_start) >= today)
  const pastWeeks = weeks.filter(w => new Date(w.week_start) < today)

  return [
    ...futureWeeks.sort((a, b) => compareDates(a.week_start, b.week_start)),
    ...pastWeeks.sort((a, b) => compareDates(a.week_start, b.week_start)),
  ]
}

const sortHoldingsByWeekStart = (holdings: HoldingDto[]): HoldingDto[] => {
  const today = new Date()

  return holdings.sort((a, b) => {
    const aDate = new Date(a.weeks[0].week_start)
    const bDate = new Date(b.weeks[0].week_start)

    const isAPast = aDate < today
    const isBPast = bDate < today

    if (isAPast && !isBPast) return 1
    if (!isAPast && isBPast) return -1

    return compareDates(aDate, bDate)
  })
}

export const selectHoldingsWithNextWeeks: (holdings: HoldingDto[]) => HoldingDto[] = flow(
  map(holding => ({
    ...holding,
    weeks: sortWeeksByDate(holding.weeks),
  })),
  filter(flow(getPropertyValue('weeks'), isNotEmpty)),
  sortHoldingsByWeekStart
)
