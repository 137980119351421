import type { HoldingDto } from '@hcr/api/consumer'
import type { CarouselProps } from '@hcr/ui'

import { HoldingPreview } from './HoldingPreview'

export const createHoldingPreview = () => {
  return (dto: HoldingDto): CarouselProps['children'][number] => {
    return [String(dto.holding_id), <HoldingPreview holding={dto} key={dto.holding_id} />]
  }
}
