import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Stack, styled, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import type { FC } from 'react'

import type { BenefitsGroupDto } from '@hcr/api/optimizely'
import { clampCss, COLORS, ICONS, LinkUnstyled, optimizelyCss, unit } from '@hcr/ui'

import { Path } from '../../../../../../models'
import { to } from '../../../../../../utils'

interface BenefitPreviewProps {
  benefit: BenefitsGroupDto['content'][number]
}

export const BenefitPreview: FC<BenefitPreviewProps> = ({ benefit }) => {
  return (
    <Box bgcolor={COLORS.vaalea['60%']}>
      <StyledButton
        component={LinkUnstyled}
        to={to([Path.OwnerBenefitDetails, { benefitId: String(benefit.benefitId) }])}
        color='black'
      >
        <Stack>
          <Typography variant='labelM'>{benefit.title}</Typography>
          <Typography variant='headlineExtraExtraSmall' marginTop={unit(1.5)} color={COLORS.burgundi[500]}>
            {benefit.subtitle}
          </Typography>
          <Typography
            variant='bodyM'
            marginTop={unit(2.5)}
            css={[optimizelyCss, clampCss(3)]}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(benefit.description) }}
          />
        </Stack>
        <FontAwesomeIcon icon={ICONS.farChevronRight} fontSize={unit(5.5)} />
      </StyledButton>
    </Box>
  )
}

const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  gap: ${unit(2)};
  padding: ${unit(3.5)} ${unit(7)} ${unit(4.5)} ${unit(4)};
  font-weight: initial;
` as typeof Button
