import { use as i18nextUse } from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

import type { Locale } from '../models'
import { LocaleNamespace } from '../models'

interface CreateI18nextLoaderOptions {
  locale: Locale
}

export const createI18nextLoader = ({ locale }: CreateI18nextLoaderOptions) => {
  return i18nextUse(initReactI18next)
    .use(
      resourcesToBackend(
        (lang: string, ns: string) =>
          import(
            /* webpackMode: "lazy" */
            `../assets/translations/${lang}/${ns}.json`
          )
      )
    )
    .init({
      ns: Object.values(LocaleNamespace),
      defaultNS: LocaleNamespace.Main,
      fallbackLng: locale.lang,
      lng: locale.lang,
    })
}
