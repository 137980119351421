import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import type { To } from 'react-router'

import type { ActivityStatusDto, PackageTicketStatusDto, SingleTicketStatusDto } from '@hcr/api/consumer'
import { Chip, COLORS, ICONS, LinkUnstyled, UNICODE, unit, serratedEdgeVerticalCss } from '@hcr/ui'
import { isNotUndefined } from '@hcr/utils'

import { LocaleNamespace } from '../../../../../models'

interface TicketPreviewProps {
  guests?: string
  guestsIcon?: IconDefinition
  link: To
  name: string
  status: ActivityStatusDto | PackageTicketStatusDto | SingleTicketStatusDto
  type: string
  validity: string
}

export const TicketPreview: FC<TicketPreviewProps> = ({ guests, guestsIcon, link, name, status, type, validity }) => {
  const { t } = useTranslation()

  return (
    <Container status={status} to={link}>
      <Content>
        <Header>
          <Section>
            <Type status={status} variant='titleExtraSmall'>
              {type}
            </Type>
            <Typography variant='headlineExtraSmall'>{name}</Typography>
          </Section>
          <Box>
            <Chip text={t(status, { ns: LocaleNamespace.TicketStatus })} variant={status} />
          </Box>
        </Header>
        <Section>
          <Guests>
            {isNotUndefined(guestsIcon) && (
              <GuestsIcon status={status}>
                <FontAwesomeIcon icon={guestsIcon} />
              </GuestsIcon>
            )}
            <Typography variant='labelS'>{guests ?? UNICODE.nbsp}</Typography>
          </Guests>
          <Typography variant='meta'>{validity}</Typography>
        </Section>
      </Content>
      <ChevronIcon status={status}>
        <FontAwesomeIcon icon={ICONS.farChevronRight} />
      </ChevronIcon>
    </Container>
  )
}

const Container = styled(LinkUnstyled)<{
  readonly status: ActivityStatusDto | PackageTicketStatusDto | SingleTicketStatusDto
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  pointer-events: ${props => props.status === 'Used' && 'none'};
  background-color: ${props => (props.status !== 'Used' ? COLORS.white : COLORS.hiekka[500])};

  // Extra space between tickets with different statuses
  &:not(:last-of-type) {
    margin-bottom: ${unit(2.5)};
  }

  ${serratedEdgeVerticalCss};
`

const Content = styled(Stack)`
  gap: ${unit(6)};
  padding: ${unit(4)};
  width: 100%;
`

const Section = styled(Stack)`
  align-items: flex-start;
  text-align: left;
`

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: ${unit(2)};
`

const Type = styled(Typography)<{
  readonly status: ActivityStatusDto | PackageTicketStatusDto | SingleTicketStatusDto
}>`
  color: ${props => (props.status !== 'Used' ? COLORS.grey[600] : COLORS.kaarna['60%'])};
`

const Guests = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${unit(2)};
`

const GuestsIcon = styled(Box)<{ readonly status: ActivityStatusDto | PackageTicketStatusDto | SingleTicketStatusDto }>`
  color: ${props => (props.status !== 'Used' ? COLORS.grey[600] : COLORS.kaarna['60%'])};
  font-size: ${unit(3.5)};
`

const ChevronIcon = styled(Box)<{
  readonly status: ActivityStatusDto | PackageTicketStatusDto | SingleTicketStatusDto
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${unit(4)};
  border-left: ${props =>
    props.status !== 'Used'
      ? `${unit(0.5)} dashed ${COLORS.vaalea['60%']}`
      : `${unit(0.5)} dashed ${COLORS.vaalea[500]}`};
  color: ${props => (props.status !== 'Used' ? COLORS.black : COLORS.vaalea[700])};
  font-size: ${unit(7)};

  &::before,
  &::after {
    position: absolute;
    content: '';
    height: ${unit(4)};
    width: ${unit(4)};
    background-color: ${COLORS.vaalea['60%']};
    border-radius: 50%;
  }

  &::before {
    top: 0;
    transform: translateY(-65%);
  }

  &::after {
    bottom: 0;
    transform: translateY(65%);
  }
`
