import type { FC } from 'react'
import { useParams } from 'react-router'

import { unit } from '@hcr/ui'

import { useServicesQuery } from '../../../hooks'
import type { ServicesPathParams } from '../../../models'
import { createServicesContent, isNotUndefinedOrEmptyPathParam, isUndefinedOrEmptyPathParam } from '../../../utils'
import { LayoutNavigationError404 } from '../../common'

import { LayoutServices, LayoutServicesLoading } from './common'
import { ServicesEmpty } from './ServicesEmpty'

const servicesContent = createServicesContent({ slidesMargin: unit(6) })

export const Services: FC = () => {
  const params = useParams<ServicesPathParams>()

  const services = useServicesQuery(
    { destinationId: String(params.destinationId) },
    { enabled: isNotUndefinedOrEmptyPathParam(params.destinationId) }
  )

  if (isUndefinedOrEmptyPathParam(params.destinationId)) {
    return <ServicesEmpty />
  }

  if (services.isError) {
    return <LayoutNavigationError404 showContextMenuButton />
  }

  if (services.isSuccess) {
    return (
      <LayoutServices description={services.data.description} title={services.data.title}>
        {services.data.content.map(servicesContent)}
      </LayoutServices>
    )
  }

  return <LayoutServicesLoading />
}
