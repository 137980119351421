import { Stack, Button } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'
import { useState, type FC } from 'react'
import { useSearchParams } from 'react-router'

import { createCouponsQueryKey } from '@hcr/api/consumer'
import { unit, Alert, ButtonLoading } from '@hcr/ui'
import { find, isNotUndefined, hasPropertyValue, isNotNull, isUndefined, logger } from '@hcr/utils'

import { useIdToken, useCouponsQuery, useCouponDetailsQuery, useCouponActivationMutation } from '../../../hooks'
import { Path } from '../../../models'
import { to, isNotUndefinedOrEmptyPathParam, createCouponValidityString } from '../../../utils'
import { LayoutNavigationBackError500, LayoutNavigationBackLoading } from '../../common'

import { LayoutCouponDetailsActivation, LayoutCouponDetailsInactive } from './common'

interface CouponDetailsInactiveProps {
  contentId: string
  couponId: string
}

const BACK_NAVIGATION_PATH = to(Path.Home)

export const CouponDetailsInactive: FC<CouponDetailsInactiveProps> = ({ couponId, contentId }) => {
  const idToken = useIdToken()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const openConfirmation = () => setIsConfirmationOpen(true)
  const cancelConfirmation = () => setIsConfirmationOpen(false)

  const coupon = useCouponsQuery(
    { idToken: String(idToken) },
    {
      select: find(hasPropertyValue('coupon_id', couponId)),
      enabled: isNotNull(idToken),
    }
  )

  const couponDetails = useCouponDetailsQuery(
    { couponId: String(contentId) },
    {
      enabled: isNotUndefinedOrEmptyPathParam(contentId) && isNotUndefined(contentId),
    }
  )

  const couponActivation = useCouponActivationMutation({ idToken: String(idToken) })

  const handleActivation = async () => {
    try {
      await couponActivation.mutateAsync({ couponId })
      queryClient.removeQueries({ queryKey: createCouponsQueryKey() })

      const updatedSearchParams = new URLSearchParams(searchParams)

      return setSearchParams(updatedSearchParams)
    } catch {
      logger.error('Failed to activate the coupon:', coupon.data)
      cancelConfirmation()
    }
  }

  if (coupon.isError || (coupon.isSuccess && isUndefined(coupon.data))) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (coupon.isSuccess && isNotUndefined(coupon.data) && couponDetails.isSuccess) {
    if (isNotUndefined(couponDetails.data)) {
      const { caption, description, heading, iconUrl, imageUrl, toBeActivated } = couponDetails.data

      return isConfirmationOpen ? (
        <LayoutCouponDetailsActivation backNavigationPath={BACK_NAVIGATION_PATH} couponImageUrl={imageUrl}>
          <Stack sx={{ gap: unit(1.5), marginTop: unit(1), padding: unit(4) }}>
            <ButtonLoading
              onClick={handleActivation}
              isLoading={couponActivation.isPending}
              variant='contained'
              color='success'
            >
              {t('coupons.confirm')}
            </ButtonLoading>

            <Button onClick={cancelConfirmation} disabled={couponActivation.isPending} variant='outlined' color='black'>
              {t('coupons.cancel')}
            </Button>
          </Stack>
        </LayoutCouponDetailsActivation>
      ) : (
        <LayoutCouponDetailsInactive
          backNavigationPath={BACK_NAVIGATION_PATH}
          caption={caption}
          couponIconUrl={iconUrl}
          couponImageUrl={imageUrl}
          description={description}
          heading={heading}
          toBeActivated={toBeActivated}
          isUsed={coupon.data.is_used}
          validity={createCouponValidityString(coupon.data)}
        >
          {toBeActivated ? (
            <Stack sx={{ gap: unit(1.5), marginTop: unit(2), padding: unit(4) }}>
              {couponActivation.isError && (
                <Alert
                  severity='error'
                  title={t('coupons.activation-failed')}
                  description={t('coupons.an-error-occurred-while-activate-coupon')}
                />
              )}

              <Button onClick={openConfirmation} variant='contained' color='success'>
                {t('coupons.use-benefit')}
              </Button>
            </Stack>
          ) : null}
        </LayoutCouponDetailsInactive>
      )
    }

    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
