import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS, ICONS, unit } from '@hcr/ui'

import { BookingListDescription } from '../../../booking/booking/common'
import type { BookingListPreviewProps } from '../../../common'

export const BookingListPreview: FC<BookingListPreviewProps> = ({
  dateRange,
  destinationName,
  onClick,
  ticketsCount,
}) => {
  const { t } = useTranslation()

  return (
    <Row>
      <RowButton onClick={onClick}>
        <Stack>
          <Typography variant='headlineS'>{destinationName}</Typography>

          <BookingListDescription dateRange={dateRange} ticketsCount={ticketsCount} />
        </Stack>
        <Stack direction='row' alignItems='center' gap={unit(1.5)}>
          <FontAwesomeIcon icon={ICONS.farInfoCircle} />
          <Typography variant='bodyM' sx={{ textDecoration: 'underline' }}>
            {t('home.info')}
          </Typography>
        </Stack>
      </RowButton>
    </Row>
  )
}

const Row = styled(Box)`
  margin-left: ${unit(-5)};
  margin-right: ${unit(-5)};
  border-top: thin solid ${COLORS.hiekka[700]};

  :last-of-type {
    border-bottom: thin solid ${COLORS.hiekka[700]};
  }
`

const RowButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: ${unit(4)};
  text-align: left;
  padding: ${unit(5)};
` as typeof Button
