import { Stack, Typography } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import type { To } from 'react-router'

import { unit } from '@hcr/ui'

import { LayoutNavigationBack } from '../../../../common'

interface LayoutHistoryProps extends PropsWithChildren {
  backNavigationPath: To
}

export const LayoutHistory: FC<LayoutHistoryProps> = ({ backNavigationPath, children }) => {
  const { t } = useTranslation()

  return (
    <LayoutNavigationBack to={backNavigationPath} arrowVariant='text'>
      <Stack gap={unit(4)} paddingTop={unit(24)} paddingX={unit(7)} paddingBottom={unit(4.5)}>
        <Typography variant='headlineL'>{t('history.booking-history')}</Typography>
        <Typography variant='bodyM'>{t('history.here-you-can-view-all-your-bookings')}</Typography>
      </Stack>
      {children}
    </LayoutNavigationBack>
  )
}
