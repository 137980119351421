export enum Mocks {
  HasAccommodations = 'hasAccommodations',
  HasActiveContracts = 'hasActiveContracts',
  HasActivities = 'hasActivities',
  HasBenefits = 'hasBenefits',
  HasCoupons = 'hasCoupons',
  HasHotelRoom = 'hasHotelRoom',
  HasSingleTickets = 'hasSingleTickets',
  Holding = 'holding',
  IsB2BBasicOwner = 'isB2BBasicOwner',
  IsB2BGoldOwner = 'isB2BGoldOwner',
  IsB2BVillasOwner = 'isB2BVillasOwner',
  IsGoldOwner = 'isGoldOwner',
  IsVillasOwner = 'isVillasOwner',
}
