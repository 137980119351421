import type { FC } from 'react'
import type { To } from 'react-router'

import { LoadingState } from '@hcr/ui'

import { LayoutNavigationBack } from './LayoutNavigationBack'

interface LayoutNavigationBackLoadingProps {
  to: To
}

export const LayoutNavigationBackLoading: FC<LayoutNavigationBackLoadingProps> = ({ to }) => {
  return (
    <LayoutNavigationBack to={to} arrowVariant='text'>
      <LoadingState />
    </LayoutNavigationBack>
  )
}
