import { useMemo } from 'react'

import type { AccommodationDto } from '@hcr/api/consumer'
import { hasPetFee } from '@hcr/api/consumer'
import { getPropertyValue, isNotNull, sort } from '@hcr/utils'

import { useAccommodationsQuery, useSingleTicketsQuery, useActivitiesQuery, useIdToken } from '../../../../hooks'
import { compareDates } from '../../../../utils'

interface Item {
  resort_id: number
}

const groupByResortId = (items: Item[]): Record<string, { singleTickets: number }> => {
  return items.reduce(
    (acc, item) => {
      const resortId = item.resort_id

      if (!acc[resortId]) {
        acc[resortId] = { singleTickets: 0 }
      }

      acc[resortId].singleTickets += 1

      return acc
    },
    {} as Record<string, { singleTickets: number }>
  )
}

const calculateAccommodationTickets = (accommodations: AccommodationDto[]) => {
  return accommodations.reduce(
    (acc, accommodation) => {
      const resortId = accommodation.resort_id

      if (!acc[resortId]) {
        acc[resortId] = {}
      }

      acc[resortId][accommodation.accommodation_id] = accommodation.package_tickets.reduce(
        (ticketAcc, ticket) =>
          ticketAcc + (hasPetFee(ticket.category_id) ? 1 : accommodation.adults + accommodation.children),
        acc[resortId][accommodation.accommodation_id] || 0
      )

      return acc
    },
    {} as Record<string, Record<string, number>>
  )
}

export const useTicketsCount = () => {
  const idToken = useIdToken()

  const accommodations = useAccommodationsQuery(
    { idToken: String(idToken) },
    {
      select: sort({ comparer: compareDates, asc: getPropertyValue('start_date') }),
      enabled: isNotNull(idToken),
    }
  )

  const singleTickets = useSingleTicketsQuery(
    { idToken: String(idToken) },
    {
      select: data => data.filter(ticket => ticket.status !== 'Used'),
      enabled: isNotNull(idToken),
    }
  )

  const activities = useActivitiesQuery(
    { idToken: String(idToken) },
    {
      select: data => data.filter(activity => activity.status !== 'Used'),
      enabled: isNotNull(idToken),
    }
  )

  const ticketsCount = useMemo(() => {
    const singleTicketsCount = groupByResortId(singleTickets.data ?? [])
    const activitiesCount = groupByResortId(activities.data ?? [])
    const accommodationsCount = calculateAccommodationTickets(accommodations.data ?? [])

    const combinedCount: Record<string, Record<string, number> & { singleTickets: number }> = { ...singleTicketsCount }

    for (const [resortId, tickets] of Object.entries(activitiesCount)) {
      if (!combinedCount[resortId]) {
        combinedCount[resortId] = { singleTickets: 0 }
      }
      combinedCount[resortId].singleTickets = (combinedCount[resortId].singleTickets ?? 0) + tickets.singleTickets
    }

    for (const [resortId, accommodations] of Object.entries(accommodationsCount)) {
      if (!combinedCount[resortId]) {
        combinedCount[resortId] = { singleTickets: 0 }
      }

      Object.assign(combinedCount[resortId], accommodations)
    }

    return combinedCount
  }, [singleTickets.data, activities.data, accommodations.data])

  return ticketsCount
}
