import { Box, CardMedia, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { useParams } from 'react-router'

import { isCarouselDto } from '@hcr/api/optimizely'
import { COLORS, hideEmptyCss, unit } from '@hcr/ui'
import { isNotNull, isNotUndefined, isUndefined, not } from '@hcr/utils'

import { useServiceDetailsQuery } from '../../../hooks'
import type { ServiceDetailsPathParams } from '../../../models'
import { EMPTY_PATH_PARAM, Path } from '../../../models'
import {
  createLink,
  createServiceDetailsContent,
  isNotUndefinedOrEmptyPathParam,
  isUndefinedOrEmptyPathParam,
  to,
} from '../../../utils'
import {
  ExpandableText,
  LayoutCta,
  LayoutNavigationBack,
  LayoutNavigationBackError404,
  LayoutNavigationBackLoading,
} from '../../common'

const serviceDetailsContent = createServiceDetailsContent({ slidesMargin: unit(8) })

export const ServiceDetails: FC = () => {
  const params = useParams<ServiceDetailsPathParams>()

  const BACK_NAVIGATION_PATH = to([Path.Services, { destinationId: params.destinationId ?? EMPTY_PATH_PARAM }])

  const serviceDetails = useServiceDetailsQuery(
    { destinationId: String(params.destinationId), serviceId: String(params.serviceId) },
    {
      enabled: isNotUndefinedOrEmptyPathParam(params.destinationId) && isNotUndefined(params.serviceId),
    }
  )

  if (isUndefinedOrEmptyPathParam(params.destinationId) || isUndefined(params.serviceId) || serviceDetails.isError) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (serviceDetails.isSuccess) {
    return (
      <LayoutNavigationBack to={BACK_NAVIGATION_PATH} arrowVariant='outlined'>
        <Box bgcolor={COLORS.white} minHeight='100vh'>
          <LayoutCta link={isNotNull(serviceDetails.data.link) ? createLink(serviceDetails.data.link) : null}>
            <CardMedia image={serviceDetails.data.imageUrl} sx={{ height: unit(94) }} />
            <Stack gap={unit(4)} paddingY={unit(9)} paddingX={unit(8)}>
              <Typography variant='headlineL'>{serviceDetails.data.title}</Typography>
              <ExpandableText value={serviceDetails.data.description} />
            </Stack>
            <Stack gap={unit(3)} paddingX={unit(3)} paddingBottom={unit(3)} css={hideEmptyCss}>
              {serviceDetails.data.content.filter(not(isCarouselDto)).map(serviceDetailsContent)}
            </Stack>
            <Box paddingX={unit(8)} paddingBottom={unit(3)} css={hideEmptyCss}>
              {serviceDetails.data.content.filter(isCarouselDto).map(serviceDetailsContent)}
            </Box>
          </LayoutCta>
        </Box>
      </LayoutNavigationBack>
    )
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
