import type { FC, PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import { isUndefined } from '@hcr/utils'

import { Path } from '../models'
import { isBookingPath, isHomePath, isOwnerPath, isServicesPath } from '../utils'

interface LastPathContext {
  recent: string
  root: string
}

const DEFAULT_LAST_PATH: LastPathContext = {
  recent: Path.Home,
  root: Path.Home,
}

const LastPathContext = createContext<LastPathContext>(DEFAULT_LAST_PATH)

type LastPathProviderProps = PropsWithChildren

export const LastPathProvider: FC<LastPathProviderProps> = ({ children }) => {
  const location = useLocation()
  const lastPath = useRef<LastPathContext>(DEFAULT_LAST_PATH)

  useEffect(() => {
    const isRootPath =
      isBookingPath(location.pathname) ||
      isHomePath(location.pathname) ||
      isOwnerPath(location.pathname) ||
      isServicesPath(location.pathname)

    lastPath.current = {
      recent: location.pathname,
      root: isRootPath ? location.pathname : lastPath.current.root,
    }
  }, [location.pathname])

  return <LastPathContext.Provider value={lastPath.current}>{children}</LastPathContext.Provider>
}

export const useLastPath = (): LastPathContext => {
  const context = useContext(LastPathContext)

  if (isUndefined(context)) {
    throw new Error('useLastPath must be used within LastPathProvider')
  }

  return context
}
