import { useMemo } from 'react'
import type { To } from 'react-router'

import { isUndefined } from '@hcr/utils'

import { useLastPath } from '../../../../contexts'
import { Path } from '../../../../models'
import { isOwnerHoldingWeeksPath, to } from '../../../../utils'

interface UseBackNavigationPathOptions {
  holdingId: string | undefined
}

export const useBackNavigationPath = ({ holdingId }: UseBackNavigationPathOptions): To => {
  const lastPath = useLastPath()

  return useMemo((): To => {
    if (isUndefined(holdingId)) {
      return to(Path.Owner)
    }

    if (isOwnerHoldingWeeksPath(lastPath.recent)) {
      return to([Path.OwnerHoldingWeeks, { holdingId }])
    }

    return to([Path.OwnerHoldingDetails, { holdingId }])
  }, [lastPath.recent, holdingId])
}
