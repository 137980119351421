import { Button, Stack, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import DOMPurify from 'dompurify'
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router'

import { createHoldingsQueryKey } from '@hcr/api/consumer'
import { Alert, ButtonLoading, LinkUnstyled, optimizelyCss, unit } from '@hcr/ui'
import { isNotUndefined, isUndefined, logger } from '@hcr/utils'

import { useHoldingBookingMutation, useIdToken } from '../../../../hooks'
import { Path, type OwnerHoldingBookingPathParams } from '../../../../models'
import { createHoldingNameString, to } from '../../../../utils'
import { LayoutNavigationBackError404 } from '../../../common'
import { ContractPreview, LayoutOwnerHoldingAction, LayoutOwnerHoldingActionLoading } from '../common'
import { useBackNavigationPath, useHolding, useWeek } from '../hooks'

export const OwnerHoldingBooking: FC = () => {
  const { t } = useTranslation()
  const idToken = useIdToken()
  const params = useParams<OwnerHoldingBookingPathParams>()
  const queryClient = useQueryClient()

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const openConfirmation = () => setIsConfirmationOpen(true)
  const cancelConfirmation = () => setIsConfirmationOpen(false)

  const holding = useHolding({ holdingId: params.holdingId })
  const holdingBooking = useHoldingBookingMutation({ idToken: String(idToken) })
  const week = useWeek({ holding: holding.data, weekStart: params.weekStart })

  const backNavigationPath = useBackNavigationPath({ holdingId: params.holdingId })

  const handleBooking = async () => {
    try {
      if (isNotUndefined(holding.data) && isNotUndefined(week)) {
        await holdingBooking.mutateAsync({
          contract_id: holding.data.contract_id,
          end_date: week.week_end,
          room_number: holding.data.room_number,
          start_date: week.week_start,
          week_number: week.week_number,
        })
        queryClient.removeQueries({ queryKey: createHoldingsQueryKey() })
      }
    } catch {
      logger.error('Failed to book the week:', week)
      cancelConfirmation()
    }
  }

  if (
    isUndefined(params.holdingId) ||
    isUndefined(params.weekStart) ||
    holding.isError ||
    (holding.isSuccess && isUndefined(holding.data)) ||
    (holding.isSuccess && isUndefined(week))
  ) {
    return <LayoutNavigationBackError404 to={backNavigationPath} />
  }

  if (holding.isSuccess && isNotUndefined(holding.data) && isNotUndefined(week)) {
    if (week.is_reservation_request_received) {
      return (
        <LayoutOwnerHoldingAction
          backNavigationPath={backNavigationPath}
          header={<Typography variant='headlineL'>{t('owner-holding.booked')}</Typography>}
        >
          <Typography variant='bodyM'>{t('owner-holding.your-booking-request-has-been-sent')}</Typography>
          <ContractPreview
            buildingId={holding.data.building_id}
            holdingName={createHoldingNameString(holding.data)}
            week={week}
          />

          <Button component={LinkUnstyled} to={backNavigationPath} variant='outlined' color='black'>
            {t('owner-holding.close')}
          </Button>

          <Button
            component={LinkUnstyled}
            to={to([
              Path.OwnerHoldingBookingCancel,
              { holdingId: holding.data.holding_id, weekStart: week.week_start },
            ])}
            variant='text'
            color='primary'
          >
            {t('owner-holding.cancel-reservation')}
          </Button>
        </LayoutOwnerHoldingAction>
      )
    }

    if (week.is_locked) {
      return <Navigate to={backNavigationPath} replace />
    }

    return (
      <LayoutOwnerHoldingAction
        backNavigationPath={backNavigationPath}
        drawer={
          <>
            <Typography variant='headlineS' marginBottom={unit(3)}>
              {t('owner-holding.booking-request')}
            </Typography>
            <Typography
              variant='bodyM'
              css={optimizelyCss}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  t(
                    'owner-holding.book-your-villa-for-your-own-use-at-least-one-month-before-the-start-of-the-season-drawer'
                  )
                ),
              }}
            />
          </>
        }
        header={<Typography variant='headlineL'>{t('owner-holding.make-a-booking-request')}</Typography>}
      >
        <ContractPreview
          buildingId={holding.data.building_id}
          holdingName={createHoldingNameString(holding.data)}
          week={week}
        />
        <Alert
          severity='info'
          title={t('owner-holding.info')}
          description={t(
            'owner-holding.book-your-villa-for-your-own-use-at-least-one-month-before-the-start-of-the-season'
          )}
        />
        {holdingBooking.isError && (
          <Alert
            severity='error'
            title={t('owner-holding.booking-failed')}
            description={t('owner-holding.an-error-occurred-while-booking')}
          />
        )}
        {isConfirmationOpen ? (
          <Stack gap={unit(1.5)}>
            <ButtonLoading
              onClick={handleBooking}
              isLoading={holdingBooking.isPending}
              variant='contained'
              color='success'
            >
              {t('owner-holding.confirm')}
            </ButtonLoading>
            <Button onClick={cancelConfirmation} disabled={holdingBooking.isPending} variant='outlined' color='black'>
              {t('owner-holding.cancel')}
            </Button>
          </Stack>
        ) : (
          <Button onClick={openConfirmation} variant='contained' color='primary'>
            {t('owner-holding.book')}
          </Button>
        )}
      </LayoutOwnerHoldingAction>
    )
  }

  return <LayoutOwnerHoldingActionLoading backNavigationPath={backNavigationPath} />
}
