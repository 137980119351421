import { type FC } from 'react'
import { useParams, useSearchParams } from 'react-router'

import { find, hasPropertyValue, isEmpty, isNotEmpty, isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import { useActivitiesQuery, useIdToken } from '../../../../hooks'
import type { TicketDetailsActivityPathParams } from '../../../../models'
import { Path, TicketDetailsActivitySearchParamsKeys } from '../../../../models'
import { to } from '../../../../utils'
import { LayoutNavigationBackError404, LayoutNavigationBackLoading } from '../../../common'

import { TicketDetailsActivityActive } from './TicketDetailsActivityActive'
import { TicketDetailsActivityInactive } from './TicketDetailsActivityInactive'

const BACK_NAVIGATION_PATH = to(Path.Booking)

export const TicketDetailsActivity: FC = () => {
  const idToken = useIdToken()
  const params = useParams<TicketDetailsActivityPathParams>()
  const [searchParams] = useSearchParams()

  const resortsIdsSearchParam = searchParams.get(TicketDetailsActivitySearchParamsKeys.ResortsIds)
  const resortsIds = isNotNull(resortsIdsSearchParam)
    ? resortsIdsSearchParam.split(',').filter(isNotEmpty).map(Number).filter(Number.isInteger)
    : []

  const activity = useActivitiesQuery(
    { idToken: String(idToken), resortsIds },
    {
      select: find(hasPropertyValue('activity_id', params.activityId)),
      enabled: isNotNull(idToken) && isNotUndefined(params.activityId) && isNotEmpty(resortsIds),
    }
  )

  if (
    isUndefined(params.activityId) ||
    isEmpty(resortsIds) ||
    activity.isError ||
    (activity.isSuccess && isUndefined(activity.data))
  ) {
    return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
  }

  if (activity.isSuccess && isNotUndefined(activity.data)) {
    switch (activity.data.status) {
      case 'Active':
        return <TicketDetailsActivityActive activityId={params.activityId} resortsIds={resortsIds} />
      case 'Inactive':
        return <TicketDetailsActivityInactive activityId={params.activityId} resortsIds={resortsIds} />
      case 'Used':
        return <LayoutNavigationBackError404 to={BACK_NAVIGATION_PATH} />
    }
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
