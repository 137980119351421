import type { FC } from 'react'
import type { To } from 'react-router'

import { Error404 } from '../error'

import { LayoutNavigationBack } from './LayoutNavigationBack'

interface LayoutNavigationBackError404Props {
  to: To
}

export const LayoutNavigationBackError404: FC<LayoutNavigationBackError404Props> = ({ to }) => {
  return (
    <LayoutNavigationBack to={to} arrowVariant='text'>
      <Error404 />
    </LayoutNavigationBack>
  )
}
