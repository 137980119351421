import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, CardMedia, styled } from '@mui/material'
import type { FC, PropsWithChildren } from 'react'
import type { To } from 'react-router'

import { COLORS, ICONS, LinkUnstyled, serratedEdgeHorizontalCss, unit, Z_INDEX } from '@hcr/ui'

import { LayoutBase } from '../../../../common'

interface LayoutBackNavigateCouponProps extends PropsWithChildren {
  headerImageUrl: string
  to: To
}

export const LayoutBackNavigateCoupon: FC<LayoutBackNavigateCouponProps> = ({ children, headerImageUrl, to }) => {
  return (
    <LayoutBase>
      <Box sx={{ padding: `0 ${unit(3)} ${unit(3)}}` }}>
        <ArrowLinkIcon to={to} sx={{ backgroundColor: COLORS.white, border: `${unit(0.5)} solid ${COLORS.black}` }}>
          <FontAwesomeIcon icon={ICONS.farArrowLeft} />
        </ArrowLinkIcon>

        <Box css={serratedEdgeHorizontalCss} bgcolor={COLORS.ruoho['60%']} paddingBottom={unit(8)}>
          <StyledCardMedia image={headerImageUrl} />

          {children}
        </Box>
      </Box>
    </LayoutBase>
  )
}

const ArrowLinkIcon = styled(LinkUnstyled)`
  width: ${unit(14)};
  height: ${unit(14)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: ${unit(3)};
  top: ${unit(4)};
  z-index: ${Z_INDEX.navigation};
  font-size: ${unit(6)};
`

const StyledCardMedia = styled(CardMedia)`
  height: ${unit(48.5)};

  ${props => props.theme.breakpoints.up('md')} {
    height: ${unit(71)};
  }
`
