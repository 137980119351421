import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack, styled, Typography } from '@mui/material'
import type { FC } from 'react'

import { COLORS, ICONS, unit } from '@hcr/ui'

import type { BookingListPreviewProps } from '../../../../common'
import { BookingListDescription } from '../booking-list-description'

export const BookingListPreview: FC<BookingListPreviewProps> = ({
  dateRange,
  destinationName,
  onClick,
  ticketsCount,
}) => {
  return (
    <Container onClick={onClick}>
      <Stack>
        <Typography variant='headlineExtraSmall'>{destinationName}</Typography>

        <BookingListDescription dateRange={dateRange} ticketsCount={ticketsCount} />
      </Stack>
      <FontAwesomeIcon icon={ICONS.farChevronRight} fontSize={unit(5.5)} color={COLORS.black} />
    </Container>
  )
}

const Container = styled(Button)`
  background-color: ${COLORS.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${unit(4)};
  text-align: left;
  padding: ${unit(5)} ${unit(4)};
` as typeof Button
