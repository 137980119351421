import { Box, Divider, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'
import type { To } from 'react-router'

import { COLORS, unit } from '@hcr/ui'

import { LayoutNavigationBack } from '../../../common'
import { WeekPreviewLoading } from '../common'

interface OwnerHoldingDetailsLoadingProps {
  backNavigationPath: To
}

export const OwnerHoldingDetailsLoading: FC<OwnerHoldingDetailsLoadingProps> = ({ backNavigationPath }) => {
  return (
    <LayoutNavigationBack to={backNavigationPath} arrowVariant='outlined'>
      <Box bgcolor={COLORS.white} minHeight='100vh'>
        <Skeleton height={unit(71)} variant='rectangular' />
        <Stack gap={unit(4)} paddingY={unit(9)} paddingX={unit(8)}>
          <Stack gap={unit(1.5)}>
            <Skeleton width='80%' height={unit(9.5)} variant='rectangular' />
            <Skeleton width='65%' height={unit(6.5)} variant='rectangular' />
            <Skeleton width={unit(35)} height={unit(6)} />
          </Stack>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <WeekPreviewLoading />
          <Box>
            <Skeleton width='95%' height={unit(6)} />
            <Skeleton width='85%' height={unit(6)} />
            <Skeleton width='90%' height={unit(6)} />
            <Skeleton width='95%' height={unit(6)} />
            <Skeleton width='85%' height={unit(6)} />
            <Skeleton width='95%' height={unit(6)} />
            <Skeleton width='85%' height={unit(6)} />
            <Skeleton width='90%' height={unit(6)} />
            <Skeleton width='95%' height={unit(6)} />
            <Skeleton width='85%' height={unit(6)} />
          </Box>
        </Stack>
      </Box>
    </LayoutNavigationBack>
  )
}
