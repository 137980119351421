import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Stack } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ICONS, InformationBanner, LinkUnstyled, unit } from '@hcr/ui'
import { find, flow, getPropertyValue, hasPropertyValue, isNotNull, isNotUndefined, isUndefined } from '@hcr/utils'

import { useActivitiesQuery, useDestinationDetailsQuery, useDestinationsQuery, useIdToken } from '../../../../hooks'
import { LocaleNamespace, Path } from '../../../../models'
import {
  createActivityNameString,
  createActivityServiceReservationValidityString,
  createActivityValidityString,
  isDestinationOf,
  to,
} from '../../../../utils'
import { LayoutNavigationBackError500, LayoutNavigationBackLoading } from '../../../common'
import { LayoutTicketDetailsInactive } from '../common'

interface TicketDetailsActivityInactiveProps {
  activityId: string
  resortsIds: number[]
}

const BACK_NAVIGATION_PATH = to(Path.Booking)

export const TicketDetailsActivityInactive: FC<TicketDetailsActivityInactiveProps> = ({ activityId, resortsIds }) => {
  const { t } = useTranslation()
  const idToken = useIdToken()

  const activity = useActivitiesQuery(
    { idToken: String(idToken), resortsIds },
    {
      select: find(hasPropertyValue('activity_id', activityId)),
      enabled: isNotNull(idToken),
    }
  )

  const destination = useDestinationsQuery({
    select: flow(getPropertyValue('destinations'), find(isDestinationOf(activity.data))),
    enabled: isNotUndefined(activity.data),
  })

  const destinationDetails = useDestinationDetailsQuery(
    { destinationId: String(destination.data?.destinationId) },
    { enabled: isNotUndefined(destination.data?.destinationId) }
  )

  if (
    activity.isError ||
    (activity.isSuccess && isUndefined(activity.data)) ||
    destination.isError ||
    (destination.isSuccess && isUndefined(destination.data)) ||
    destinationDetails.isError
  ) {
    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  if (activity.isSuccess && destination.isSuccess && destinationDetails.isSuccess) {
    if (isNotUndefined(activity.data)) {
      return (
        <LayoutTicketDetailsInactive
          additionalInfo={[
            {
              label: t('tickets.payment-status'),
              value: t(activity.data.payment_status, { ns: LocaleNamespace.ActivityPaymentStatus }),
            },
            { label: t('tickets.resort'), value: destinationDetails.data.name },
            ...(activity.data.service_reservations.length > 0
              ? [
                  {
                    label: t('tickets.additional-services'),
                    value: activity.data.service_reservations.map(createActivityServiceReservationValidityString),
                  },
                ]
              : []),
          ]}
          cta={
            <Button component={LinkUnstyled} to={BACK_NAVIGATION_PATH} variant='outlined' color='black'>
              {t('tickets.back-to-booking')}
            </Button>
          }
          backNavigationPath={BACK_NAVIGATION_PATH}
          name={createActivityNameString(activity.data)}
          type={t('activity', { ns: LocaleNamespace.TicketType })}
          validity={
            <>
              <Box>{createActivityValidityString(activity.data)}</Box>
              <Box>{t('tickets.automatic-activation-activity')}</Box>
            </>
          }
        >
          {isNotNull(activity.data.description) || isNotNull(activity.data.cancellation_term) ? (
            <Stack gap={unit(1.5)} marginTop={unit(5.5)}>
              <InformationBanner
                description={
                  <>
                    <Box>{activity.data.description}</Box>
                    <Box>{activity.data.cancellation_term}</Box>
                  </>
                }
                icon={<FontAwesomeIcon icon={ICONS.farInfoCircle} fontSize={unit(6)} />}
              />
            </Stack>
          ) : null}
        </LayoutTicketDetailsInactive>
      )
    }

    return <LayoutNavigationBackError500 to={BACK_NAVIGATION_PATH} />
  }

  return <LayoutNavigationBackLoading to={BACK_NAVIGATION_PATH} />
}
