import type { FC } from 'react'
import { useMemo } from 'react'

import { LoadingState } from '@hcr/ui'
import {
  filter,
  flow,
  getPropertyValue,
  hasPropertySameValue,
  isEmpty,
  isNotNull,
  isNotUndefined,
  neg,
  sort,
  unique,
} from '@hcr/utils'

import {
  useAccommodationsQuery,
  useActivitiesQuery,
  useDestinationsQuery,
  useIdToken,
  useSingleTicketsQuery,
} from '../../../hooks'
import { compareDates, isBookingInResortsOf } from '../../../utils'

import { createBookingListPreview } from './common'
import { useTicketsCount } from './hooks'
import type { BookingListPreview } from './types'

interface BookingListProps {
  empty: FC
  preview: BookingListPreview
}

export const BookingList: FC<BookingListProps> = ({ empty: Empty, preview }) => {
  const idToken = useIdToken()

  const accommodations = useAccommodationsQuery(
    { idToken: String(idToken) },
    {
      select: sort({ comparer: compareDates, asc: getPropertyValue('start_date') }),
      enabled: isNotNull(idToken),
    }
  )

  const singleTickets = useSingleTicketsQuery(
    { idToken: String(idToken) },
    {
      select: flow(filter(neg(isBookingInResortsOf(accommodations.data))), unique(hasPropertySameValue('resort_id'))),
      enabled: isNotNull(idToken) && isNotUndefined(accommodations.data),
    }
  )

  const activities = useActivitiesQuery(
    { idToken: String(idToken) },
    {
      select: flow(filter(neg(isBookingInResortsOf(accommodations.data))), unique(hasPropertySameValue('resort_id'))),
      enabled: isNotNull(idToken) && isNotUndefined(accommodations.data),
    }
  )

  const destinations = useDestinationsQuery({
    select: getPropertyValue('destinations'),
  })

  const ticketsCount = useTicketsCount()

  const bookingListPreview = useMemo(
    () => createBookingListPreview({ component: preview, ticketsCount }),
    [preview, ticketsCount]
  )

  const uniqueResortIdsFromSingleTicketsAndActivitiesData = useMemo(
    () =>
      [...(activities.data ?? []), ...(singleTickets.data ?? [])].filter(
        (item, index, array) => array.findIndex(element => element.resort_id === item.resort_id) === index
      ),
    [activities.data, singleTickets.data]
  )

  const bookingListPreviewsData = useMemo(
    () => [accommodations.data, uniqueResortIdsFromSingleTicketsAndActivitiesData].filter(isNotUndefined).flat(),
    [accommodations.data, uniqueResortIdsFromSingleTicketsAndActivitiesData]
  )

  if (accommodations.isPending || activities.isPending || singleTickets.isPending || destinations.isPending) {
    return <LoadingState />
  }

  if (
    isEmpty(bookingListPreviewsData) ||
    destinations.isError ||
    (destinations.isSuccess && isEmpty(destinations.data))
  ) {
    return <Empty />
  }

  return bookingListPreviewsData.map(bookingListPreview)
}
